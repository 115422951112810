<template>
  <div class="profile-page flexbox vertical">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>个人资料</span>
      <span></span>
    </header>
    <main class="page-body flex-fill">
      <van-form @submit="onSubmit">
        <div class="form-field">
          <div class="label">登录账号</div>
          <van-field readonly :value="userInfo.loginid" />
        </div>
        <div class="form-field">
          <div class="label">手机</div>
          <van-field
            readonly
            v-model="form.tel"
            placeholder="请填写您的绑定手机号"
          />
        </div>
        <div class="form-field">
          <div class="label">邮箱</div>
          <van-field
            v-model="form.email"
            placeholder="请填写您的邮箱地址"
            clearable
            :rules="[{ required: true, message: '请填写您的邮箱地址' }]"
          />
        </div>
        <div class="form-field">
          <div class="label">姓名</div>
          <van-field
            v-model="form.name"
            placeholder="请填写您的名字"
            clearable
            :rules="[{ required: true, message: '请填写您的名字' }]"
          />
        </div>
        <div class="form-field">
          <div class="label">性别</div>
          <van-radio-group v-model="form.sex" direction="horizontal">
            <van-radio :name="true">男</van-radio>
            <van-radio :name="false">女</van-radio>
          </van-radio-group>
        </div>
        <div class="form-field">
          <div class="label">联系地址</div>
          <div class="flexbox">
            <van-field v-model="form.province" placeholder="省份" />
            <van-field v-model="form.city" class="ml-m" placeholder="城市" />
          </div>
        </div>
        <div class="form-field">
          <div class="label">街道门牌</div>
          <van-field v-model="form.address" />
        </div>
        <div class="form-field">
          <div class="label">头像</div>
          <!-- <van-uploader v-model="form.files" :max-count="1" /> -->
          <van-uploader
            v-model="form.files"
            :max-count="1"
            :after-read="onRead"
          >
            <img
              style="width: 100px;height: 100px"
              class="head-img"
              :src="imgUrl"
              ref="goodsImg"
            />
          </van-uploader>
        </div>
        <van-button
          class="btn-submit"
          round
          block
          type="info"
          native-type="submit"
        >
          提交
        </van-button>
      </van-form>
    </main>
  </div>
</template>
<script>
import { updateUserInfo } from '@/api/account.js';
import { BASE_URL } from '@/api/index.js';
export default {
  data() {
    return {
      form: {
        tel: '',
        email: '',
        name: '',
        sex: true,
        province: '',
        city: '',
        address: '',
        files: [],
      },
      imgUrl: null,
    };
  },
  computed: {
    userInfo() {
      return this.$store.getters.userInfo;
    },
  },
  watch: {
    userInfo: {
      handler() {
        this.form.tel = this.userInfo.tel;
        this.form.name = this.userInfo.name;
        this.form.email = this.userInfo.email;
        this.form.sex = this.userInfo.sex;
        this.form.province = this.userInfo.province;
        this.form.city = this.userInfo.city;
        this.form.address = this.userInfo.address;
        // this.form.files = [{ url: this.userInfo.avatar }];
        this.imgUrl = this.userInfo.avatar;
        if (this.imgUrl != undefined && !this.imgUrl.startsWith('http')) {
          this.imgUrl = BASE_URL + this.imgUrl;
        }
        console.log(this.imgUrl);
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    afterRead(data) {
      console.log(data);
    },
    onRead(file) {
      this.$refs.goodsImg.src = file.content;
    },
    onSubmit() {
      //todo:提交数据
      updateUserInfo({
        email: this.form.email,
        name: this.form.name,
        bsex: this.form.sex,
        province: this.form.province,
        city: this.form.city,
        address: this.form.address,
        files: this.form.files,
      }).then(res => {
        if (res.Result) {
          this.$store.dispatch('getUserInfo');
          this.$router.go(-1);
          this.userInfo.tel = this.form.tel;
          this.userInfo.name = this.form.name;
          this.userInfo.email = this.form.email;
          this.userInfo.sex = this.form.sex;
          this.userInfo.province = this.form.province;
          this.userInfo.city = this.form.city;
          this.userInfo.address = this.form.address;
          this.userInfo.avatar = this.imgUrl;
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-body {
  padding: rem(60px);
  background-color: #fff;
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  overflow: visible;
}
.form-field {
  margin-bottom: rem(40px);
}
.ml-m {
  margin-left: rem(20px);
}
/deep/ .van-field__error-message {
  position: absolute;
  bottom: 0;
  transform: translateY(150%);
}
/deep/ .van-field--error .van-field__control {
  color: inherit;
}
/deep/ .van-field--error .van-field__control::placeholder {
  color: #ccc;
}
</style>
